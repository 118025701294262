import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

function handleChange(dealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (isVPPFromFIEEnabled: boolean): void =>
        updateDealerCallback(null, {
            ...dealer,
            isVPPFromFIEEnabled
        });
}

const IsVPPFromFIEEnabled: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = !!props.dealer.isVPPFromFIEEnabled;

    const toolTip =
        // eslint-disable-next-line max-len
        'This is a feature-toggle-like setting, and it will be deleted after the full feature release. Its purpose is to support gradual rollout for all dealers.';

    return (
        <DR3RadioBooleanField
            trueOption="F&I Express"
            falseOption="Dealer Track"
            label={ToggleLabelMap.isVPPFromFIEEnabled}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={toolTip}
        />
    );
};
export default IsVPPFromFIEEnabled;
