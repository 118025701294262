import * as React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3TriButtonField } from '../common/DR3TriButtonField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

type ValueOption = 'yes' | 'no' | 'legacy';

const valueMappings: Record<ValueOption, { hasAccessories: boolean; isAccessoriesCatalogEnabled: boolean }> = {
    yes: { hasAccessories: false, isAccessoriesCatalogEnabled: true },
    no: { hasAccessories: false, isAccessoriesCatalogEnabled: false },
    legacy: { hasAccessories: true, isAccessoriesCatalogEnabled: false }
};

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: string): void => {
        const newDealer = {
            ...oldDealer,
            products: {
                ...oldDealer.products,
                ...valueMappings[value as ValueOption]
            }
        };
        updateDealerCallback(null, newDealer);
    };
}

const toolTip = `Setting "Is Accessories Catalog Active?" to "Yes" will enable accessories from the PartSites catalog; 
  to "Legacy" - the current legacy accessories catalog will be used; 
  to "No" - accessories catalogues are disabled (only possible to add accessories manually via MV)`;

const AccessoriesActive: FunctionComponent<ICommonManagerFieldProps> = ({ dealer, updateDealerCallback }) => {
    if (!dealer) {
        return null;
    }

    let value: ValueOption = 'no';
    if (dealer.products.hasAccessories) {
        value = 'legacy';
    }
    if (dealer.products.isAccessoriesCatalogEnabled) {
        value = 'yes';
    }

    return (
        <DR3TriButtonField
            label={ToggleLabelMap.hasAccessories}
            tooltip={toolTip}
            handleChange={handleChange(dealer, updateDealerCallback)}
            trueOption="Legacy"
            falseOption="No"
            thirdOption="Yes"
            value={value}
            dealer={dealer}
        />
    );
};

export default AccessoriesActive;
