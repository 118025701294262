import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        width: '40%'
    },
    searchIcon: {
        width: '0.8em'
    },
    clearIcon: {
        width: '0.7em'
    }
});
