import { createStore, combineReducers } from 'redux';
import { accessoriesReducer } from './accessories/reducer';
import { managerReducer } from './manager/reducers';
import { reduxMiddleware } from './middlwareUtils';

const rootReducer = combineReducers({
    manager: managerReducer,
    accessories: accessoriesReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const store = createStore(rootReducer, reduxMiddleware);
    return store;
}

export type RootStore = ReturnType<typeof configureStore>;
