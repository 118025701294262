import { IAuthFn } from '../../utils/AuthTypes';
import { IApi } from '../../utils/Api';

export interface IRawAccessory {
    id: string;
    version: number;
    manufacturer: string;
    partNumber: string;
    fullPartName: string | null;
    shortTitle: string;
    description: string;
    disclaimer: string;
    msrp: number | null;
    cost: number | null;
    laborUnits: number | null;
    category: string;
    subcategory: string;
    productImageURL: string;
    vehicleYear: number;
    vehicleMake: string;
    vehicleModel: string;
    modelCode: string | null;
    modelTrim: string;
    trimCode: string | null;
    activationDate: string | undefined;
    deactivationDate?: string | undefined;
}

export interface IAccessory {
    id: string;
    version: number;
    manufacturer: string;
    partNumber: string;
    fullPartName: string | null;
    shortTitle: string;
    description: string;
    disclaimer: string;
    msrp: number | null;
    cost: number | null;
    laborUnits: number | null;
    category: string;
    subcategory: string;
    productImageURL: string;
    vehicleYear: number;
    vehicleMake: string;
    vehicleModel: string;
    modelCode: string | null;
    modelTrim: string;
    trimCode: string | null;
    activationDate: string | undefined;
    deactivationDate?: string | undefined;
    isActive: boolean;
}

export interface IAccessoryWithUser extends IAccessory {
    editorName: string;
}

export interface IUploadStatus {
    fileName: string;
    uploadInitiator: string;
    status: FileUploadStatus | null;
    successfullyProcessedCount: number;
    processedWithErrorsCount: number;
    uploadedAt: string;
    errorReportUrl?: string;
}

export interface IAccessoriesProps {
    list: IAccessory[];
    uploadStatus: IUploadStatus;
    auth: IAuthFn;
    api: IApi;
    isLoading: boolean;
}

export interface IAccessoriesTableProps {
    list: IAccessory[];
}

export interface IProgressBannerProps {
    uploadStatus: IUploadStatus;
    auth: IAuthFn;
    api: IApi;
}

export interface AccessoriesContainerProps {
    fetchAccessories: (auth: IAuthFn, api: IApi) => void;
    fetchUploadStatus: (auth: IAuthFn, api: IApi) => void;
    auth: IAuthFn;
    api: IApi;
    list: IAccessory[];
    isLoading: boolean;
    uploadStatus: IUploadStatus;
}

export interface IUploadStatusNotFound {
    status: number;
    statusText: string;
}

// Redux Types
export interface IAccessoriesState {
    list: IAccessory[];
    isLoading: boolean;
    uploadStatus: IUploadStatus;
    accessory: IAccessory;
    isLoadingForEdit: boolean;
}
export interface IAccessoriesAction {
    type: ActionType;
    payload?: any;
}

export enum ActionType {
    fetchAccessoriesRequest = 'FETCH_ACCESSORIES_REQUEST',
    fetchAccessoriesSuccess = 'FETCH_ACCESSORIES_SUCCESS',
    fetchAccessoriesError = 'FETCH_ACCESSORIES_ERROR',
    fetchUploadStatusSuccess = 'FETCH_UPLOAD_STATUS_SUCCESS',
    fetchUploadStatusError = 'FETCH_UPLOAD_STATUS_ERROR',
    fetchSearchAccessoriesRequest = 'FETCH_SEARCH_ACCESSORIES_REQUEST',
    fetchSearchAccessoriesSuccess = 'FETCH_SEARCH_ACCESSORIES_SUCCESS',
    fetchSearchAccessoriesError = 'FETCH_SEARCH_ACCESSORIES_ERROR',
    fetchAccessoryByIdRequest = 'FETCH_ACCESSORY_BY_ID_REQUEST',
    fetchAccessoryByIdSuccess = 'FETCH_ACCESSORY_BY_ID_SUCCESS',
    fetchAccessoryByIdError = 'FETCH_ACCESSORY_BY_ID_ERROR',
    fetchEditAccessoryRequest = 'FETCH_EDIT_ACCESSORY_REQUEST',
    fetchEditAccessorySuccess = 'FETCH_EDIT_ACCESSORY_SUCCESS',
    fetchEditAccessoryError = 'FETCH_EDIT_ACCESSORY_ERROR'
}

export enum FileUploadStatus {
    processing = 'PROCESSING',
    success = 'SUCCESS',
    fail = 'FAIL'
}
