import { Button } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import MySnackbarContentWrapper from '../../../../containers/components/MySnackbarContentWrapper';
import { useStyles } from '../Filters.styles';
import { IWithAuthProps } from '../../../../utils/AuthTypes';
import { IWithApiProps } from '../../../../utils/withApi';
import { INVALID_TOKEN_ERROR } from '../../Accessories.constants';

const TEMPLATE_DOWNLOAD_ERROR = 'Accessory template was not uploaded.';
const FILE_NAME = 'template.xlsx';

interface ComponentProps extends IWithApiProps, IWithAuthProps {}

export const DownloadAccessoriesTemplate = (props: ComponentProps) => {
    const [errorMessage, setErrorMessage] = useState('');

    const closeError = () => setErrorMessage('');
    const showErrorMessage = (message: string) => setErrorMessage(message);

    const handleDownloadTemplate = async (): Promise<void> => {
        const token = await props.auth.getAccessToken();
        if (!token) {
            showErrorMessage(INVALID_TOKEN_ERROR);
            return;
        }
        try {
            const downloadTemplateSignedURL = await props.api.asyncGenerateDownloadAccessoriesTemplateSignedURL(token);
            await props.api.asyncDownloadFile(FILE_NAME, downloadTemplateSignedURL);
        } catch (error) {
            showErrorMessage(TEMPLATE_DOWNLOAD_ERROR);
        }
    };

    const classes = useStyles();

    return (
        <>
            <Button color="primary" id="download-template" className={classes.button} onClick={handleDownloadTemplate}>
                Download Template
            </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{ top: '80px' }}
                open={Boolean(errorMessage)}
                autoHideDuration={6000}
                onClose={closeError}
            >
                <MySnackbarContentWrapper variant="error" className="alertError" message={[errorMessage]} onClose={closeError} />
            </Snackbar>
        </>
    );
};

export default DownloadAccessoriesTemplate;
