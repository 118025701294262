import React from 'react';
import { RouteComponentProps } from 'react-router';
import GlobalWrapper from '../components/layout/GlobalWrapper';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import DealerSearchDisplay, { constructSearchQuery } from '../components/DealerSearchDisplay';
import DealerMetricsDisplay from '../components/DealerMetricsDisplay';
import DealerToggleMetricsMatchList from '../components/DealerToggleMetricsMatchList';
import SearchContainerTabs from '../components/common/SearchContainerTabs';
import { AppConstants } from '../constants/AdminConstants';
import { IDealerToManage, IUpdateSearchedDealersCallbackFn, SEARCH_TAB_LABELS } from '../store/manager/types';
import { RootState } from '../store';
import { asDealerToManage } from '../store/manager/selectors';
import { fetchSearchedDealers } from '../store/manager/actions';
import { IWithAuthProps } from '../utils/AuthTypes';
import withApi, { IWithApiProps } from '../utils/withApi';
import { Config } from '../config';
import AccessoriesContainer from '../components/accessories/AccessoriesContainer';
import UtilitiesContainer from '../components/utilities/UtilitiesContainer';
import { DealXGClientManagerContainer } from '../components/dealxgClientSetup/DealXGClientManagerContainer';

interface IDealerSearchParams {
    accessories?: string;
}

interface IDealerSearchContainerProps extends IWithApiProps, IWithAuthProps, RouteComponentProps<IDealerSearchParams> {
    dealers: IDealerToManage[] | null;
    fetchSearchedDealersAction: typeof fetchSearchedDealers;
}

interface IDealerSearchContainerState {
    currentTab: SEARCH_TAB_LABELS;
    currentTabValue: any;
    dealersMatchingMetrics: any[];
    totalDealerCount: number;
    matchedDealerCount: number;
    accessToken: string;
    partnersData: any[];
    showdealxgClientSetup: boolean;
}

class DealerSearchContainer extends React.Component<IDealerSearchContainerProps, IDealerSearchContainerState> {
    constructor(props: IDealerSearchContainerProps) {
        super(props);
        this.updateSearchedDealersCallback = this.updateSearchedDealersCallback.bind(this);
        this.loadSearchedDealers = this.loadSearchedDealers.bind(this);
        if (!this.props.history.location.state) {
            this.props.history.location.state = { tab: 0 };
        }
        const { tab } = this.props.history.location.state as any;
        this.state = {
            currentTab: tab | SEARCH_TAB_LABELS.DEALER_SEARCH,
            currentTabValue: tab,
            dealersMatchingMetrics: [],
            totalDealerCount: 0,
            matchedDealerCount: 0,
            accessToken: '',
            partnersData: [],
            showdealxgClientSetup: false
        };
    }

    public async componentDidMount() {
        const accessToken = await this.props.auth.getAccessToken();
        if (accessToken) {
            const urlQueryAddition = constructSearchQuery('', 100, 1, false, false);

            this.setState({
                accessToken,
                showdealxgClientSetup: this.props.match.params.accessories === AppConstants.ADMIN_MODE
            });
            this.loadSearchedDealers(accessToken, urlQueryAddition);
        }
    }

    public onTabChange = (event: any, value: any) => {
        const tab = event.target.textContent;
        this.setState({ currentTab: tab, currentTabValue: value });
    };

    public updateSearchedDealersCallback: IUpdateSearchedDealersCallbackFn = (err, dealers?) => {
        if (err || !dealers) {
            // TODO add error handling
        } else {
            this.props.fetchSearchedDealersAction(dealers);
        }
    };
    public loadSearchedDealers = async (accessToken: string, urlQueryAddition: string): Promise<void> => {
        const response = await this.props.api.asyncGetSearchedDealers(accessToken, urlQueryAddition);
        const dealersFromSearch: any = [];
        let dealers: any = [];
        if (!response.data) {
            dealers = JSON.parse(JSON.parse(JSON.stringify(response)));
        } else {
            dealers = response;
        }
        if (response) {
            dealers.data.forEach((dealer: IDealerToManage) => {
                dealersFromSearch.push(asDealerToManage(dealer));
            });
            this.props.fetchSearchedDealersAction(dealersFromSearch);
        }
    };

    public getDealerToggleMetrics = async (toggles: any): Promise<void> => {
        const response = await this.props.api.asyncGetDealerToggleMetrics(toggles, this.state.accessToken);
        this.setState({
            dealersMatchingMetrics: response.dealerResponse.dealerIds,
            totalDealerCount: response.dealerResponse.totalDealerCount,
            matchedDealerCount: response.dealerResponse.matchedDealerCount,
            partnersData: response.dealerResponse.partnersData
        });

        return response;
    };

    public cloneDealer = async (dealer: IDealerToManage): Promise<void> => {
        const accessToken = await this.props.auth.getAccessToken();
        const dealerId = dealer.dealerId;
        const request = {
            dealerId
        };
        if (accessToken) {
            const clonedDealer = await this.props.api.asyncCloneDealer(request, accessToken);
            const newDealer = asDealerToManage(clonedDealer);
            const protocol = Config.services.api.protocol;
            const host = Config.services.api.host;
            const dealerIdString = newDealer.dealerId.toString();
            const redirectPath = `${protocol}${host}/dealers/${dealerIdString}/manage`;
            window.open(redirectPath, '_blank');
        }
    };

    public resetDealerMetricsSearch = () => {
        this.setState({
            dealersMatchingMetrics: [],
            totalDealerCount: 0,
            matchedDealerCount: 0
        });
    };

    public renderSearchContainerView(dealer: any) {
        switch (this.state.currentTabValue) {
            case SEARCH_TAB_LABELS.DEALER_SEARCH: {
                return (
                    <DealerSearchDisplay
                        dealers={this.props.dealers}
                        updateSearchedDealersCallback={this.updateSearchedDealersCallback}
                        loadSearchedDealers={this.loadSearchedDealers}
                        auth={this.props.auth}
                        cloneDealer={this.cloneDealer}
                    />
                );
            }
            case SEARCH_TAB_LABELS.ACCESSORIES: {
                return <AccessoriesContainer />;
            }
            case SEARCH_TAB_LABELS.DEALER_METRICS: {
                return (
                    <React.Fragment>
                        <DealerMetricsDisplay
                            getDealerToggleMetrics={this.getDealerToggleMetrics}
                            dealer={dealer}
                            resetDealerMetricsSearch={this.resetDealerMetricsSearch}
                        />
                        <DealerToggleMetricsMatchList
                            matchedDealers={this.state.dealersMatchingMetrics}
                            matchedDealerCount={this.state.matchedDealerCount}
                            totalDealerCount={this.state.totalDealerCount}
                            partnersData={this.state.partnersData}
                        />
                    </React.Fragment>
                );
            }
            case SEARCH_TAB_LABELS.UTILITIES: {
                return <UtilitiesContainer />;
            }
            case SEARCH_TAB_LABELS.DEALXG_CLIENT_SETUP: {
                return <DealXGClientManagerContainer auth={this.props.auth} />;
            }
            default: {
                return (
                    <DealerSearchDisplay
                        dealers={this.props.dealers}
                        updateSearchedDealersCallback={this.updateSearchedDealersCallback}
                        loadSearchedDealers={this.loadSearchedDealers}
                        auth={this.props.auth}
                        cloneDealer={this.cloneDealer}
                    />
                );
            }
        }
    }

    public render() {
        if (!this.props.dealers) {
            return null;
        }
        const showAccessories = this.props.match.params.accessories === AppConstants.ACCESSORIES_MODE;
        const showUtilities = Config.env !== 'prod' && Config.env !== 'production';
        return (
            <GlobalWrapper dealer={this.props.dealers[0]}>
                <SearchContainerTabs
                    onTabChange={this.onTabChange}
                    currentTab={this.state.currentTab}
                    currentTabValue={this.state.currentTabValue}
                    showAccessories={showAccessories}
                    showUtilities={showUtilities}
                    showdealxgClientSetup={this.state.showdealxgClientSetup}
                />
                {this.renderSearchContainerView(this.props.dealers[0])}
            </GlobalWrapper>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    dealers: state.manager.fetchSearchedDealersalers || null
});
const mapReducersToProps = {
    fetchSearchedDealersAction: fetchSearchedDealers
};
export default connect(mapStateToProps, mapReducersToProps)(withAuth(withApi(DealerSearchContainer)));
