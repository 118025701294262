import { IAccessory } from '../types';

export const emptyAccessoryMockData: IAccessory = {
    id: '',
    version: 0,
    manufacturer: '',
    partNumber: '',
    fullPartName: '',
    shortTitle: '',
    description: '',
    disclaimer: '',
    msrp: 0,
    cost: 0,
    laborUnits: 0,
    category: '',
    subcategory: '',
    productImageURL: '',
    vehicleYear: 0,
    vehicleMake: '',
    vehicleModel: '',
    modelCode: '',
    modelTrim: '',
    trimCode: 'N/A',
    activationDate: '',
    deactivationDate: '',
    isActive: false
};
