import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        marginTop: '40px',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: 'none',
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        fontSize: '0.875rem',
        lineHeight: '1.8',
        letterSpacing: '0.01071em',
        border: 'solid ipx #D2D2D2',
        borderRadius: '4px',
        backgroundColor: '#E8E8E8',
        display: 'flex',
        padding: '6px 16px'
    },
    error: {
        color: '#FF1E1A'
    },
    success: {
        color: '#008000'
    },
    link: {
        color: '#FF1E1A!important',
        textDecoration: 'underline!important'
    }
});
