import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import * as React from 'react';
import { useStyles } from './Filters.styles';
import UploadNewAccessoriesContainer from './UploadNewAccessories/UploadNewAccessoriesContainer';
import DownloadAccessoriesTemplateContainer from './DownloadAccessoriesTemplate/DownloadAccessoriesTemplateContainer';
import SearchAccessoriesContainer from './Search/SearchAccessoriesContainer';

const Filters = () => {
    const classes = useStyles();
    return (
        <>
            <Box display="flex" justifyContent="space-between" className={classes.root}>
                <SearchAccessoriesContainer />
                <Box display="flex">
                    <DownloadAccessoriesTemplateContainer />
                    <UploadNewAccessoriesContainer />
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <span className={classes.filterText}>Filter:</span>
                <FormControlLabel control={<Checkbox name="showActive" />} label="Show active accessories" />
            </Box>
        </>
    );
};

export default Filters;
