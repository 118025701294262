import { ActionType, IAccessoriesAction, IAccessoriesState } from './types';
import { uploadStatusInitialState } from './__mocks__/uploadStatus.mock';
import { emptyAccessoryMockData } from './__mocks__/accessoryEmpty.mock';

export const initialState: IAccessoriesState = {
    list: [],
    isLoading: false,
    uploadStatus: uploadStatusInitialState,
    accessory: emptyAccessoryMockData,
    isLoadingForEdit: false
};

export const accessoriesReducer = (state = initialState, action: IAccessoriesAction): IAccessoriesState => {
    switch (action.type) {
        case ActionType.fetchAccessoriesRequest: {
            return { ...state, list: [], isLoading: true };
        }

        case ActionType.fetchAccessoriesSuccess: {
            return { ...state, list: action.payload, isLoading: false };
        }
        case ActionType.fetchAccessoriesError: {
            return { ...state, isLoading: false };
        }
        case ActionType.fetchUploadStatusSuccess: {
            return { ...state, uploadStatus: action.payload };
        }
        case ActionType.fetchUploadStatusError: {
            return { ...state };
        }
        case ActionType.fetchSearchAccessoriesRequest: {
            return { ...state, list: [], isLoading: true };
        }

        case ActionType.fetchSearchAccessoriesSuccess: {
            return { ...state, list: action.payload, isLoading: false };
        }
        case ActionType.fetchSearchAccessoriesError: {
            return { ...state, isLoading: false };
        }

        case ActionType.fetchAccessoryByIdRequest: {
            return { ...state, accessory: emptyAccessoryMockData, isLoadingForEdit: true };
        }
        case ActionType.fetchAccessoryByIdSuccess: {
            return { ...state, accessory: action.payload, isLoadingForEdit: false };
        }
        case ActionType.fetchAccessoryByIdError: {
            return { ...state, isLoadingForEdit: false };
        }
        default: {
            return state;
        }
    }
};
