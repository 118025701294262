import { getDateWithoutTime } from '../../../common/utils';

export const isNumberFieldValid = (value: string | number | Date | null): boolean => {
    return /^(0|[1-9]\d*)\.?[0-9]{0,2}$/.test(value as string);
};

export const isGreaterOrEqualToCurrentDate = (date: Date): boolean => {
    const currentDate = new Date();
    return getDateWithoutTime(date) >= getDateWithoutTime(currentDate);
};

export const isActivationDateEqualToDeactivationDate = (activationDate: Date, deactivationDate: Date): boolean => {
    return activationDate.toDateString() === deactivationDate.toDateString();
};

export const isActivationDateLessThanDeactivationDate = (activationDate: Date, deactivationDate: Date): boolean => {
    return getDateWithoutTime(deactivationDate) > getDateWithoutTime(activationDate);
};

export const isFieldEmpty = (value: string | number | null) => {
    return !value;
};
