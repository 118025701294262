import { Dispatch } from 'redux';
import { ActionType, IAccessoriesAction, IAccessory, IRawAccessory, IUploadStatus } from './types';
import { IAuthFn } from '../../utils/AuthTypes';
import { IApi } from '../../utils/Api';
import { mapAccessoriesFields } from './mapAccessories';

const fetchAccessoryList = async (auth: IAuthFn, api: IApi): Promise<IRawAccessory[]> => {
    const accessToken = await auth.getAccessToken();
    if (accessToken) {
        const result: IRawAccessory[] = await api.asyncFetchAccessories(accessToken);
        return result;
    } else {
        return [];
    }
};
export const fetchAccessories = (auth: IAuthFn, api: IApi) => async (dispatch: Dispatch<IAccessoriesAction>): Promise<void> => {
    try {
        dispatch({ type: ActionType.fetchAccessoriesRequest });

        const response: IRawAccessory[] = await fetchAccessoryList(auth, api);
        const mappedAccessories: IAccessory[] = mapAccessoriesFields(response);

        dispatch({
            type: ActionType.fetchAccessoriesSuccess,
            payload: mappedAccessories
        });
    } catch (error) {
        dispatch({ type: ActionType.fetchAccessoriesError });
    }
};

export const fetchUploadStatus = (auth: IAuthFn, api: IApi) => async (dispatch: Dispatch<IAccessoriesAction>): Promise<void> => {
    const accessToken = await auth.getAccessToken();
    if (!accessToken) throw new Error('No access token');
    try {
        const response: IUploadStatus = await api.asyncFetchUploadStatus(accessToken);

        dispatch({
            type: ActionType.fetchUploadStatusSuccess,
            payload: response
        });
    } catch (error) {
        dispatch({ type: ActionType.fetchUploadStatusError });
    }
};

export const fetchSearchAccessories = (auth: IAuthFn, api: IApi, searchText: string) => async (
    dispatch: Dispatch<IAccessoriesAction>
): Promise<void> => {
    const accessToken = await auth.getAccessToken();
    if (!accessToken) throw new Error('No access token');
    try {
        dispatch({ type: ActionType.fetchSearchAccessoriesRequest });

        const response: IRawAccessory[] = await api.asyncFetchSearchAccessories(searchText, accessToken);
        const mappedAccessories: IAccessory[] = mapAccessoriesFields(response);

        dispatch({
            type: ActionType.fetchSearchAccessoriesSuccess,
            payload: mappedAccessories
        });
    } catch (error) {
        dispatch({ type: ActionType.fetchSearchAccessoriesError });
    }
};

export const fetchAccessoryById = (auth: IAuthFn, api: IApi, id: string) => async (
    dispatch: Dispatch<IAccessoriesAction>
): Promise<void> => {
    const accessToken = await auth.getAccessToken();
    if (!accessToken) throw new Error('No access token');
    try {
        dispatch({ type: ActionType.fetchAccessoryByIdRequest });

        const response: IRawAccessory = await api.asyncFetchAccessoryById(id, accessToken);
        const mappedAccessories: IAccessory = mapAccessoriesFields([response])[0];

        dispatch({
            type: ActionType.fetchAccessoryByIdSuccess,
            payload: mappedAccessories
        });
    } catch (error) {
        dispatch({ type: ActionType.fetchAccessoryByIdError });
    }
};

export const fetchEditAccessory = (auth: IAuthFn, api: IApi, accessory: IAccessory) => async (
    dispatch: Dispatch<IAccessoriesAction>
): Promise<void> => {
    const accessToken = await auth.getAccessToken();
    if (!accessToken) throw new Error('No access token');
    dispatch({ type: ActionType.fetchEditAccessoryRequest });
    const rawAccessory = { ...accessory };
    delete rawAccessory.isActive;

    try {
        const response: IRawAccessory = await api.asyncEditAccessory(rawAccessory, accessToken);
        const mappedAccessories: IAccessory = mapAccessoriesFields([response])[0];

        dispatch({
            type: ActionType.fetchEditAccessorySuccess,
            payload: mappedAccessories
        });
    } catch (error) {
        dispatch({ type: ActionType.fetchEditAccessoryError });
    }
};
