import * as yup from 'yup';

const ERROR_MSG = 'Must select at least one trade-in valuation provider';
const ERROR_MSG_DEFAULT_PROVIDER = 'Must select a default trade-in valuation provider';
const ERROR_MSG_SALES_VIEW = 'Must select at least one trade-in valuation provider (Sales View)';
const ERROR_MSG_DEFAULT_PROVIDER_SALES_VIEW = 'Must select a default trade-in valuation provider (Sales View)';
const ERROR_MSG_SHOW_APPRAISAL_VALUE_SCREEN_SALES_VIEW = 'Select if appraisal value screen will appear (Sales View)';
const ERROR_MSG_ICO_URL = 'Must set a correct URL for ICO provider';
// eslint-disable-next-line max-len
const urlRE = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;

export const improvedWorkflowSchema = yup.object().shape(
    {
        tradeInValuationProviderKBB: yup['boolean']().when(['tradeInValuationProviderKBBICO', 'tradeInValuationProviderManual'], {
            is: (kbbIco, dealerAppraisal) => kbbIco || dealerAppraisal,
            then: (schema: any) => schema['default'](false),
            otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG)
        }),
        tradeInValuationProviderKBBICO: yup['boolean']().when(['tradeInValuationProviderKBB', 'tradeInValuationProviderManual'], {
            is: (kbbGuide, dealerAppraisal) => kbbGuide || dealerAppraisal,
            then: (schema: any) => schema['default'](false),
            otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG)
        }),
        tradeInValuationProviderManual: yup['boolean']().when(['tradeInValuationProviderKBB', 'tradeInValuationProviderKBBICO'], {
            is: (kbbGuide, kbbIco) => kbbGuide || kbbIco,
            then: (schema: any) => schema['default'](false),
            otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG)
        }),
        tradeInValuationProviderDefault: yup.string().nullable().required(ERROR_MSG_DEFAULT_PROVIDER),
        tradeInValuationProviderKBBSalesView: yup['boolean']().when(
            ['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderManualSalesView'],
            {
                is: (kbbIco, dealerAppraisal) => kbbIco || dealerAppraisal,
                then: (schema: any) => schema['default'](false),
                otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG_SALES_VIEW)
            }
        ),
        tradeInValuationProviderKBBICOSalesView: yup['boolean']().when(
            ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderManualSalesView'],
            {
                is: (kbbGuide, dealerAppraisal) => kbbGuide || dealerAppraisal,
                then: (schema: any) => schema['default'](false),
                otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG_SALES_VIEW)
            }
        ),
        tradeInValuationProviderManualSalesView: yup['boolean']().when(
            ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderKBBICOSalesView'],
            {
                is: (kbbGuide, kbbIco) => kbbGuide || kbbIco,
                then: (schema: any) => schema['default'](false),
                otherwise: (schema: any) => schema.oneOf([true], ERROR_MSG_SALES_VIEW)
            }
        ),
        tradeInValuationProviderDefaultSalesView: yup.string().nullable().required(ERROR_MSG_DEFAULT_PROVIDER_SALES_VIEW),
        showAppraisalValueScreenSalesView: yup.string().required(ERROR_MSG_SHOW_APPRAISAL_VALUE_SCREEN_SALES_VIEW),
        tradeInValuationICOProviderUrl: yup
            .string()
            .when(['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderKBBICO'], {
                is: (kbbIcoSales, kbbIco) => kbbIcoSales || kbbIco,
                then: yup.string().matches(urlRE, ERROR_MSG_ICO_URL)
            })
    },
    [
        ['tradeInValuationProviderKBBICO', 'tradeInValuationProviderManual'],
        ['tradeInValuationProviderKBB', 'tradeInValuationProviderManual'],
        ['tradeInValuationProviderKBB', 'tradeInValuationProviderKBBICO'],
        ['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderManualSalesView'],
        ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderManualSalesView'],
        ['tradeInValuationProviderKBBSalesView', 'tradeInValuationProviderKBBICOSalesView'],
        ['tradeInValuationProviderKBBICOSalesView', 'tradeInValuationProviderKBBICO']
    ]
);
