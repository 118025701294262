import React, { useState, useCallback } from 'react';
import { useStyles } from './SearchAccessories.styles';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import debounce from 'lodash/debounce';
import { DELAY_FOR_REQUEST, MIN_LETTERS_FOR_START_SEARCH } from './SearchAccessories.constants';
import { IAuthFn } from '../../../../utils/AuthTypes';
import { IApi } from '../../../../utils/Api';

export interface SearchAccessoriesProps {
    fetchSearchAccessories: (auth: IAuthFn, api: IApi, searchText: string) => void;
    fetchAccessories: (auth: IAuthFn, api: IApi) => void;
    auth: IAuthFn;
    api: IApi;
}
const SearchAccessories = (props: SearchAccessoriesProps) => {
    const [value, setValue] = useState('');
    const { auth, api, fetchAccessories, fetchSearchAccessories } = props;

    const debouncedSendTextChange = useCallback(
        debounce((searchText: string) => {
            fetchSearchAccessories(auth, api, searchText);
        }, DELAY_FOR_REQUEST),
        []
    );
    const handleSearchInput = (event: React.ChangeEvent<unknown>) => {
        const searchText = (event.target as HTMLInputElement).value.trim();
        setValue(searchText);
        if (!searchText) {
            fetchAccessories(auth, api);
            return;
        }
        if (searchText.length < MIN_LETTERS_FOR_START_SEARCH) return;
        debouncedSendTextChange(searchText);
    };

    const handleClearClick = () => {
        setValue('');
        fetchAccessories(auth, api);
    };

    const classes = useStyles();

    return (
        <>
            <TextField
                placeholder="Search"
                size="small"
                variant="outlined"
                autoFocus={true}
                className={classes.root}
                value={value}
                onChange={handleSearchInput}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search className={classes.searchIcon} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <IconButton disabled={!value} onClick={handleClearClick}>
                            <Clear className={classes.clearIcon} />
                        </IconButton>
                    )
                }}
            />
        </>
    );
};

export default SearchAccessories;
