import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import withApi from '../../utils/withApi';
import { RootState } from '../../store';
import { fetchAccessories, fetchUploadStatus } from '../../store/accessories/actions';
import { selectAccessories } from '../../store/accessories/selectors';
import { AccessoriesContainerProps } from '../../store/accessories/types';
import Accessories from './Accessories';

export class AccessoriesContainer extends Component<AccessoriesContainerProps> {
    async componentDidMount() {
        const { auth, api, fetchAccessories, fetchUploadStatus } = this.props;
        fetchAccessories(auth, api);
        fetchUploadStatus(auth, api);
    }
    render() {
        return (
            <Accessories
                list={this.props.list}
                uploadStatus={this.props.uploadStatus}
                auth={this.props.auth}
                api={this.props.api}
                isLoading={this.props.isLoading}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => selectAccessories(state);

const withConnect = connect(mapStateToProps, { fetchAccessories, fetchUploadStatus });

export default withConnect(withAuth(withApi(AccessoriesContainer)));
