import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const TradeInHideExcellent: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    const dealer = props?.dealer as IDealerToManage;

    if (
        !dealer ||
        (!props.featureToggles?.enableTradeInImprovedWorkflow && dealer.tradeInValuationProvider !== 'Kelley Blue Book')
    ) {
        return null;
    }

    const tooltip = 'When this setting is "Yes", the shopper will see: "Very Good", "Good", and "Fair".';

    const handleChange = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => {
        return (value: boolean): void => {
            const newDealer = {
                ...oldDealer,
                tradeInHideExcellent: value
            };
            updateDealerCallback(null, newDealer);
        };
    };

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.tradeInHideExcellent}
            value={!!dealer.tradeInHideExcellent}
            handleChange={handleChange(dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default TradeInHideExcellent;
