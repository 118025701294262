import * as React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from './DR3DatePicker.styles';
import { DR3FieldWrapper } from '../DR3FieldWrapper';
import { FormHelperText } from '@material-ui/core';

interface ComponentProps {
    label: string;
    value: Date | null;
    onChange: (date: Date | null) => void;
    format?: string;
    disableToolbar?: boolean;
    minDate?: Date;
    disabled?: boolean;
    placeholder?: string;
    errorText?: string;
}

export const DR3DatePicker: React.FunctionComponent<ComponentProps> = (props) => {
    const classes = useStyles();

    const format = props.format || 'MM/dd/yyyy';
    const disableToolbar = props.disableToolbar !== false;
    const isDateInvalid = props.value ? props.value.toString() === 'Invalid Date' : false;
    const error = !!props.errorText || isDateInvalid;

    return (
        <DR3FieldWrapper label={props.label} className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    format={format}
                    disableToolbar={disableToolbar}
                    value={props.value}
                    onChange={props.onChange}
                    minDate={props.minDate}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    inputVariant={'outlined'}
                    error={error}
                />
            </MuiPickersUtilsProvider>
            <FormHelperText className={classes.errorText}>{props.errorText}</FormHelperText>
        </DR3FieldWrapper>
    );
};
