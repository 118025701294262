import * as React from 'react';
import { FunctionComponent } from 'react';
import {
    IDealerToManage,
    IUpdateDealerCallbackFn,
    ICommonManagerFieldProps,
    IInputAdornmentProps
} from '../../store/manager/types';
import { DR3TextFieldWithAdornment } from '../common/DR3TextFieldWithAdornment';

const handleChangeLaborRate = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => (
    value: number
): void => {
    const newDealer: IDealerToManage = {
        ...oldDealer,
        accessories: {
            ...oldDealer.accessories,
            laborRate: value
        }
    };
    updateDealerCallback(null, newDealer);
};
const AccessoriesLaborRate: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const isDisabled = !props.dealer.products.hasAccessories;
    const adornment: IInputAdornmentProps = { position: 'start', unit: '$' };
    return (
        <DR3TextFieldWithAdornment
            label="Global Labor Rate per Hour"
            disabled={isDisabled}
            value={props.dealer.accessories.laborRate}
            InputProps={adornment}
            maxLength={4}
            handleChange={handleChangeLaborRate(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default AccessoriesLaborRate;
