import { RootState } from '../index';
import { IAccessoriesState } from './types';

export const selectAccessories = (state: RootState): IAccessoriesState => ({
    list: state.accessories.list,
    isLoading: state.accessories.isLoading,
    uploadStatus: state.accessories.uploadStatus,
    accessory: state.accessories.accessory,
    isLoadingForEdit: state.accessories.isLoadingForEdit
});
