export const editFormFields = [
    { title: 'Full Part Name', field: 'fullPartName' },
    { title: 'Short title', field: 'shortTitle' },
    { title: 'Description', field: 'description' },
    { title: 'Disclaimer', field: 'disclaimer' },
    { title: 'MSRP', field: 'msrp' },
    { title: 'Cost', field: 'cost' },
    { title: 'Labor Units', field: 'laborUnits' },
    { title: 'Category', field: 'category' },
    { title: 'Sub Category', field: 'subcategory' },
    { title: 'Product image URL', field: 'productImageURL' },
    { title: 'Activation Date', field: 'activationDate' },
    { title: 'Deactivation Date', field: 'deactivationDate' }
] as const;

export const placeholder = 'mm/dd/yyyy';

export enum AccessoryEditableFields {
    MSRP = 'msrp',
    COST = 'cost',
    LABOR_UNITS = 'laborUnits',
    ACTIVATION_DATE = 'activationDate',
    DEACTIVATION_DATE = 'deactivationDate',
    FULL_PART_NAME = 'fullPartName',
    SHORT_TITLE = 'shortTitle',
    DESCRIPTION = 'description',
    DISCLAIMER = 'disclaimer',
    CATEGORY = 'category',
    SUBCATEGORY = 'subcategory',
    PRODUCT_IMAGE_URL = 'productImageURL'
}

export const formErrorsText = {
    [AccessoryEditableFields.MSRP]: 'MSRP must be a positive number, e.g. 1050.80',
    [AccessoryEditableFields.COST]: 'Cost must be a positive number, e.g. 1050.80',
    [AccessoryEditableFields.LABOR_UNITS]: 'Labor Units must be a positive number, e.g. 10.5',
    [AccessoryEditableFields.FULL_PART_NAME]: 'Full Part Name cannot be empty, the accessory name is required',
    [`${AccessoryEditableFields.ACTIVATION_DATE}.PAST`]: 'You cannot set the date in the past',
    [`${AccessoryEditableFields.ACTIVATION_DATE}.EQUAL`]: 'Activation and deactivation dates cannot be the same',
    [`${AccessoryEditableFields.ACTIVATION_DATE}.EARLIER`]: 'The activation date cannot be after the deactivation date',
    [`${AccessoryEditableFields.DEACTIVATION_DATE}.PAST`]: 'You cannot set the date in the past',
    [`${AccessoryEditableFields.DEACTIVATION_DATE}.EQUAL`]: 'Activation and deactivation dates cannot be the same',
    [`${AccessoryEditableFields.DEACTIVATION_DATE}.EARLIER`]: 'The deactivation date cannot be earlier than the activation date'
} as const;
