import { getDateWithoutTime } from '../../components/common/utils';
import { IAccessory, IRawAccessory } from './types';

export const calculateIsActive = (accessory: IRawAccessory) => {
    const today = getDateWithoutTime(new Date());
    const checkActivationDate = accessory.activationDate ? getDateWithoutTime(new Date(accessory.activationDate)) <= today : false;
    const checkDeactivationDate = accessory.deactivationDate
        ? getDateWithoutTime(new Date(accessory.deactivationDate)) > today
        : true;
    return checkActivationDate && checkDeactivationDate;
};

export const mapAccessoriesFields = (rowAccessories: IRawAccessory[]): IAccessory[] => {
    const accessoriesMapped: IAccessory[] = rowAccessories.map((accessory: IRawAccessory) => {
        const isActive = calculateIsActive(accessory);
        return { ...accessory, isActive };
    });
    return accessoriesMapped;
};
