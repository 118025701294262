import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const tooltip = 'Selecting "No" for Show Trade Decision Page disables Manual Entry Trade';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const newDealer = {
            ...oldDealer,
            enableTradeDecisionPage: value
        };
        updateDealerCallback(null, newDealer);
    };
}

const EnableTradeDecisionPage: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = !!props.dealer.enableTradeDecisionPage;
    const enableManualTradeDecision = !props.dealer.enableTradeDecisionPage ? false : !!props.dealer.enableManualTradeDecision;

    if (enableManualTradeDecision !== !!props.dealer.enableManualTradeDecision) {
        const newDealer = {
            ...props.dealer,
            enableManualTradeDecision
        };
        props.updateDealerCallback(null, newDealer);
    }

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.enableTradeDecisionPage}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
        />
    );
};
export default EnableTradeDecisionPage;
