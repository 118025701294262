import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const tooltip = 'When this setting is "Yes", the shopper can manually enter a trade estimate';

function handleChange(oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) {
    return (value: boolean): void => {
        const newDealer = {
            ...oldDealer,
            enableManualTradeDecision: value
        };
        updateDealerCallback(null, newDealer);
    };
}

const EnableManualTradeDecision: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const value = !!props.dealer.enableManualTradeDecision;
    const disabled = !props.dealer.enableTradeDecisionPage;

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.enableManualTradeDecision}
            value={value}
            handleChange={handleChange(props.dealer, props.updateDealerCallback)}
            tooltipProps={tooltip}
            disabled={disabled}
        />
    );
};
export default EnableManualTradeDecision;
