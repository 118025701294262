import * as React from 'react';
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import { IAccessory } from '../../../../store/accessories/types';
import { DR3DatePicker } from '../../../common/DR3DatePicker/DR3DatePicker';
import { DR3TextField } from '../../../common/DR3TextField';
import { DR3HardCodeField } from '../../../common/DR3HardCodeField';
import { getDateWithoutTime } from '../../../common/utils';

import themeOverride from '../../../layout/theme';
import { AccessoryEditableFields, editFormFields, formErrorsText, placeholder } from './EditForm.constants';
import { formErrorsInitialState } from './formErrorsInitialState';
import {
    isFieldEmpty,
    isNumberFieldValid,
    isGreaterOrEqualToCurrentDate,
    isActivationDateEqualToDeactivationDate,
    isActivationDateLessThanDeactivationDate
} from './validationRules';

interface ComponentProps {
    accessory: IAccessory;
}

type FormErrors = { [key in AccessoryEditableFields]: string };

interface ComponentState {
    formData: IAccessory;
    activationDate: Date | null;
    deactivationDate: Date | null;
    formErrors: FormErrors;
}

class EditForm extends React.Component<ComponentProps, ComponentState> {
    private activationDate = this.props.accessory[AccessoryEditableFields.ACTIVATION_DATE];

    private deActivationDate = this.props.accessory[AccessoryEditableFields.DEACTIVATION_DATE];

    state = {
        formData: this.props.accessory,
        activationDate: this.activationDate ? getDateWithoutTime(new Date(this.activationDate)) : null,
        deactivationDate: this.deActivationDate ? getDateWithoutTime(new Date(this.deActivationDate)) : null,
        formErrors: formErrorsInitialState
    };

    handleInputChange = (fieldName: string) => (value: string) => {
        this.validateField(fieldName, value);
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [fieldName]: value
            }
        }));
    };

    handleDateChange = (fieldName: string) => (date: Date | null) => {
        const dateWithoutTime = date && getDateWithoutTime(date);
        const isDataInvalid = dateWithoutTime && dateWithoutTime.toString() === 'Invalid Date';

        if (dateWithoutTime && !isDataInvalid) {
            this.validateDateField(fieldName, dateWithoutTime);
        } else {
            this.setErrorMessage(fieldName, '');
        }

        if (fieldName === AccessoryEditableFields.ACTIVATION_DATE) {
            if (date) {
                this.setState({ activationDate: dateWithoutTime });
            } else {
                this.setState({ activationDate: dateWithoutTime, deactivationDate: null });
            }
        }

        if (fieldName === AccessoryEditableFields.DEACTIVATION_DATE) {
            this.setState({ deactivationDate: dateWithoutTime });
        }
    };

    setErrorMessage = (fieldName: string, errorMessage: string): void => {
        this.setState((prevState) => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: errorMessage
            }
        }));
    };

    validateField = (fieldName: string, value: string | number | null) => {
        let isFieldValid;
        let errorMessage: string;

        switch (fieldName) {
            case AccessoryEditableFields.MSRP:
                isFieldValid = isNumberFieldValid(value);
                errorMessage = isFieldValid ? '' : formErrorsText[AccessoryEditableFields.MSRP];
                this.setErrorMessage(AccessoryEditableFields.MSRP, errorMessage);
                return;

            case AccessoryEditableFields.COST:
                isFieldValid = isNumberFieldValid(value);
                errorMessage = isFieldValid ? '' : formErrorsText[AccessoryEditableFields.COST];
                this.setErrorMessage(AccessoryEditableFields.COST, errorMessage);
                return;

            case AccessoryEditableFields.LABOR_UNITS:
                isFieldValid = isNumberFieldValid(value);
                errorMessage = isFieldValid ? '' : formErrorsText[AccessoryEditableFields.LABOR_UNITS];
                this.setErrorMessage(AccessoryEditableFields.LABOR_UNITS, errorMessage);
                return;

            case AccessoryEditableFields.FULL_PART_NAME:
                isFieldValid = isFieldEmpty(value);
                errorMessage = value ? '' : formErrorsText[AccessoryEditableFields.FULL_PART_NAME];
                this.setErrorMessage(AccessoryEditableFields.FULL_PART_NAME, errorMessage);
                return;
            default:
                return '';
        }
    };

    clearDateDependantErrors = (fieldName: AccessoryEditableFields) => {
        if (this.state.formErrors[fieldName] !== formErrorsText[`${fieldName}.PAST`]) {
            this.setErrorMessage(fieldName, '');
        }
    };

    validateDateField = (fieldName: string, value: Date) => {
        let isFieldValid;
        let errorMessage: string;
        switch (fieldName) {
            case AccessoryEditableFields.ACTIVATION_DATE:
                isFieldValid = isGreaterOrEqualToCurrentDate(value);
                errorMessage = isFieldValid ? '' : formErrorsText[`${AccessoryEditableFields.ACTIVATION_DATE}.PAST`];
                this.setErrorMessage(AccessoryEditableFields.ACTIVATION_DATE, errorMessage);
                if (!isFieldValid) {
                    return;
                }
                this.clearDateDependantErrors(AccessoryEditableFields.DEACTIVATION_DATE);

                isFieldValid = this.state.deactivationDate
                    ? !isActivationDateEqualToDeactivationDate(value, this.state.deactivationDate)
                    : true;
                errorMessage = isFieldValid ? '' : formErrorsText[`${AccessoryEditableFields.ACTIVATION_DATE}.EQUAL`];
                this.setErrorMessage(AccessoryEditableFields.ACTIVATION_DATE, errorMessage);
                if (!isFieldValid) {
                    return;
                }

                isFieldValid = this.state.deactivationDate
                    ? isActivationDateLessThanDeactivationDate(value, this.state.deactivationDate)
                    : true;
                errorMessage = isFieldValid ? '' : formErrorsText[`${AccessoryEditableFields.ACTIVATION_DATE}.EARLIER`];
                this.setErrorMessage(AccessoryEditableFields.ACTIVATION_DATE, errorMessage);
                return;

            case AccessoryEditableFields.DEACTIVATION_DATE:
                isFieldValid = isGreaterOrEqualToCurrentDate(value);
                errorMessage = isFieldValid ? '' : formErrorsText[`${AccessoryEditableFields.DEACTIVATION_DATE}.PAST`];
                this.setErrorMessage(AccessoryEditableFields.DEACTIVATION_DATE, errorMessage);
                if (!isFieldValid) {
                    return;
                }
                this.clearDateDependantErrors(AccessoryEditableFields.ACTIVATION_DATE);

                isFieldValid = this.state.activationDate
                    ? !isActivationDateEqualToDeactivationDate(this.state.activationDate, value)
                    : true;
                errorMessage = isFieldValid ? '' : formErrorsText[`${AccessoryEditableFields.DEACTIVATION_DATE}.EQUAL`];
                this.setErrorMessage(AccessoryEditableFields.DEACTIVATION_DATE, errorMessage);
                if (!isFieldValid) {
                    return;
                }

                isFieldValid = this.state.activationDate
                    ? isActivationDateLessThanDeactivationDate(this.state.activationDate, value)
                    : true;
                errorMessage = isFieldValid ? '' : formErrorsText[`${AccessoryEditableFields.DEACTIVATION_DATE}.EARLIER`];
                this.setErrorMessage(AccessoryEditableFields.DEACTIVATION_DATE, errorMessage);
                return;

            default:
                return '';
        }
    };
    validateFormIfNothingChange() {
        Object.keys(this.state.formData).forEach((fieldName) => {
            switch (fieldName) {
                case AccessoryEditableFields.MSRP:
                case AccessoryEditableFields.COST:
                case AccessoryEditableFields.LABOR_UNITS:
                case AccessoryEditableFields.FULL_PART_NAME:
                    this.validateField(fieldName, this.state.formData[fieldName]);
                    break;
            }
        });
    }

    validateForm() {
        return !Object.values(this.state.formErrors).find((val: string) => !!val);
    }

    render() {
        return (
            <ThemeProvider theme={themeOverride}>
                <Typography align="center" variant="h4" style={{ marginTop: '6rem' }}>
                    Edit Accessories
                </Typography>
                <Box paddingTop={'1em'} textAlign="right" alignItems="left" width="80%">
                    <DR3HardCodeField value={this.state.formData.manufacturer} label="Manufacturer" disabled />
                    <DR3HardCodeField value={this.state.formData.partNumber} label="Part Number" disabled />
                    <DR3HardCodeField value={this.state.formData.vehicleYear} label="Vehicle Year" disabled />
                    <DR3HardCodeField value={this.state.formData.vehicleMake} label="Vehicle Make" disabled />
                    <DR3HardCodeField value={this.state.formData.vehicleModel} label="Vehicle Model" disabled />

                    <DR3HardCodeField value={this.state.formData.modelCode} label="Model Code" disabled />
                    <DR3HardCodeField value={this.state.formData.modelTrim} label="Model Trim" disabled />
                    <DR3HardCodeField value={this.state.formData.trimCode} label="Trim Code" disabled />
                    <DR3HardCodeField value={this.state.formData.version} label="Version" disabled />

                    {editFormFields.map((record) => {
                        const value = this.state.formData[record.field as keyof IAccessory] as string;

                        let isDisable: boolean;

                        switch (record.field) {
                            case AccessoryEditableFields.ACTIVATION_DATE:
                                return (
                                    <DR3DatePicker
                                        label="Activation Date"
                                        value={this.state.activationDate}
                                        onChange={this.handleDateChange(record.field)}
                                        minDate={getDateWithoutTime(new Date())}
                                        placeholder={placeholder}
                                        errorText={this.state.formErrors[record.field]}
                                    />
                                );
                            case AccessoryEditableFields.DEACTIVATION_DATE:
                                isDisable = !this.state.activationDate;

                                return (
                                    <DR3DatePicker
                                        label="Deactivation Date"
                                        value={this.state.deactivationDate}
                                        onChange={this.handleDateChange(record.field)}
                                        disabled={isDisable}
                                        placeholder={placeholder}
                                        errorText={this.state.formErrors[record.field]}
                                        minDate={getDateWithoutTime(new Date())}
                                    />
                                );
                            case AccessoryEditableFields.FULL_PART_NAME:
                                return (
                                    <DR3TextField
                                        key={record.title}
                                        label={record.title}
                                        value={value}
                                        handleChange={this.handleInputChange(record.field)}
                                        withMargin={true}
                                        isRequired={true}
                                        errorText={this.state.formErrors[record.field]}
                                    />
                                );
                            default:
                                return (
                                    <DR3TextField
                                        key={record.title}
                                        label={record.title}
                                        value={value}
                                        handleChange={this.handleInputChange(record.field)}
                                        errorText={this.state.formErrors[record.field]}
                                        withMargin={true}
                                    />
                                );
                        }
                    })}
                </Box>
            </ThemeProvider>
        );
    }
}

export default EditForm;
