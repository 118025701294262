import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { useStyles } from './ProgressBanner.styles';
import { IProgressBannerProps, FileUploadStatus } from '../../../store/accessories/types';
import { formatDate } from '../AccessoriesTable/formateDate';
import { INVALID_TOKEN_ERROR } from '../Accessories.constants';
import MySnackbarContentWrapper from '../../../containers/components/MySnackbarContentWrapper';

const DOWNLOAD_ERROR_MESSAGE = 'Sorry, the Error Log File is not available, please contact support';

const ProgressBanner = (props: IProgressBannerProps) => {
    const { successfullyProcessedCount, processedWithErrorsCount, errorReportUrl, status, uploadedAt } = props.uploadStatus;
    const [errorMessage, setErrorMessage] = useState('');
    const closeError = () => setErrorMessage('');
    const showErrorMessage = (message: string) => setErrorMessage(message);
    const classes = useStyles();
    let message: JSX.Element | string = '';
    const FILE_NAME = 'errors.txt';

    const downloadFile = async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault();
        const token = await props.auth.getAccessToken();
        if (!token) {
            showErrorMessage(INVALID_TOKEN_ERROR);
            return;
        }
        try {
            await props.api.asyncDownloadFile(FILE_NAME, errorReportUrl as string);
        } catch (error) {
            showErrorMessage(DOWNLOAD_ERROR_MESSAGE);
        }
    };

    const reportUrl = errorReportUrl && (
        <a href={errorReportUrl} className={classes.link} onClick={downloadFile}>
            See details
        </a>
    );

    switch (status) {
        case FileUploadStatus.success:
            message = (
                <span className={classes.success}>
                    Great job! The file was uploaded successfully. {successfullyProcessedCount} new accessories were added. [
                    {formatDate(uploadedAt, true)}]
                </span>
            );
            break;
        case FileUploadStatus.fail:
            message = (
                <span className={classes.error}>
                    Errors found in the file. {successfullyProcessedCount} new accessories were added.
                    {processedWithErrorsCount} lines were ignored. {reportUrl} [{formatDate(uploadedAt, true)}]
                </span>
            );
            break;
        case FileUploadStatus.processing:
            message =
                'The file has been uploaded and is currently being processed, please wait and refresh the page a little later...';
    }

    return (
        <div className={classes.root}>
            {message}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{ top: '80px' }}
                open={Boolean(errorMessage)}
                autoHideDuration={6000}
                onClose={closeError}
            >
                <MySnackbarContentWrapper variant="error" className="alertError" message={[errorMessage]} onClose={closeError} />
            </Snackbar>
        </div>
    );
};

export default ProgressBanner;
