import React, { FunctionComponent } from 'react';
import { IDropdownOptions } from '../../store/manager/types';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { FormControl, Select, OutlinedInput, MenuItem } from '@material-ui/core';

interface IDR3DropdownFieldProps {
    label: string;
    value: string;
    handleChange: (value: string) => void;
    options: IDropdownOptions[];
    tooltip?: string;
    displayEmpty?: boolean;
}
// saves a string value
export const DR3DropdownField: FunctionComponent<IDR3DropdownFieldProps> = (props) => {
    const selected = props.value;
    function handleChange(event: React.ChangeEvent<unknown>) {
        const value = (event.target as HTMLInputElement).value;
        return props.handleChange(value);
    }
    return (
        <DR3FieldWrapper label={props.label} tooltip={props.tooltip}>
            <FormControl fullWidth component="fieldset">
                <Select
                    value={selected}
                    onChange={handleChange}
                    input={<OutlinedInput labelWidth={0} name={props.label} />}
                    displayEmpty={true}
                >
                    {props.options.map((element) => {
                        return (
                            <MenuItem key={element.optionValue} value={element.optionValue}>
                                {element.optionLabel}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </DR3FieldWrapper>
    );
};
