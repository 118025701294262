import { Container, Grid, ThemeProvider, CircularProgress } from '@material-ui/core';
import React from 'react';
import themeOverride from '../layout/theme';
import Filters from './Filters/Filters';
import AccessoriesTable from './AccessoriesTable/AccessoriesTable';
import { IAccessoriesProps } from '../../store/accessories/types';
import ProgressBanner from './ProgressBanner/ProgressBanner';
import { useStyles } from './Accessories.styles';

const Accessories = (props: IAccessoriesProps) => {
    const classes = useStyles();
    const table = props.list.length ? (
        <AccessoriesTable list={props.list} />
    ) : (
        <div className={classes.info}>No records to display</div>
    );
    return (
        <ThemeProvider theme={themeOverride}>
            <Container maxWidth={'lg'}>
                <Grid container>
                    <Grid item xs={12}>
                        {props.uploadStatus.status && (
                            <ProgressBanner uploadStatus={props.uploadStatus} auth={props.auth} api={props.api} />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Filters />
                    </Grid>
                    <Grid item xs={12}>
                        {props.isLoading ? <CircularProgress className={classes.spinner} /> : table}
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default Accessories;
