import { IGetDealerResponse, IDealerToManage } from '../types';
import { asSafeEmptyString, asSafeBoolean, asSafeNumber, asSafeNullString } from './selectorUtils';
import {
    asSafeAddress,
    asSafeContactOptions,
    asSafeCreditApp,
    asSafeDealerSiteToggles,
    asSafeDealerFinancing,
    asSafeDealerIncentives,
    asSafeDealerReservation,
    asSafeDealerProducts,
    asSafeDealerLeasing,
    asSafeDealerCash,
    asSafeLeadRoutingOverrideSettings,
    asSafeListingOverride,
    asSafeSponsorSetting,
    asSafeDealerAccessories
} from '.';
import asSafeCreditTier from './asSafeCreditTier';
import { asSafeProductSet } from './asSafeProductSet';

export function asDealerToManage(response: IGetDealerResponse): IDealerToManage {
    const dealer: IDealerToManage = {
        ...response,
        _id: response._id,
        dealerId: response.dealerId,
        updatedBy: response.updatedBy || '',
        updatedOn: response.updatedOn || '',
        name: asSafeEmptyString(response.name),
        address: asSafeAddress(response.address),
        contactOptions: asSafeContactOptions(response.contactOptions),
        creditApp: asSafeCreditApp(response.creditApp),
        creditApplicationButtonText: asSafeEmptyString(response.creditApplicationButtonText),
        creditApplicationURL: asSafeEmptyString(response.creditApplicationURL),
        dealerSiteToggles: asSafeDealerSiteToggles(response.dealerSiteToggles),
        dnaAccountId: asSafeEmptyString(response.dnaAccountId),
        ecommerceCTACopy: asSafeEmptyString(response.ecommerceCTACopy),
        ecommercePartner: asSafeEmptyString(response.ecommercePartner),
        ecommerceURL: asSafeEmptyString(response.ecommerceURL),
        enablePrePurchaseAgreement: asSafeBoolean(response.enablePrePurchaseAgreement),
        enableVauto: asSafeBoolean(response.enableVauto),
        esntialPartnerId: asSafeEmptyString(response.esntialPartnerId),
        esntialPartnerDealerId: asSafeNumber(response.esntialPartnerDealerId),
        excludeStdCashIncsLease: asSafeBoolean(response.excludeStdCashIncsLease),
        excludeStdCashIncsLoan: asSafeBoolean(response.excludeStdCashIncsLoan),
        excludeStdCashIncsCash: asSafeBoolean(response.excludeStdCashIncsCash),
        hasCreditDecision: asSafeBoolean(response.hasCreditDecision),
        isContinueToDeskingDisabled: asSafeBoolean(response.isContinueToDeskingDisabled),
        enableCheckoutAnywhereInMV: asSafeBoolean(response.enableCheckoutAnywhereInMV),
        isDealertrackCreditProvider: asSafeBoolean(response.isDealertrackCreditProvider),
        isDIOEnabled: asSafeBoolean(response.isDIOEnabled),
        isMenuPricingDisabled: asSafeBoolean(response.isMenuPricingDisabled),
        isMenuFitToHeightEnabled: asSafeBoolean(response.isMenuFitToHeightEnabled),
        isTCPACheckboxDisabled: asSafeBoolean(response.isTCPACheckboxDisabled),
        isTCPAEnabled: asSafeBoolean(response.isTCPAEnabled),
        isCCPAEnabled: asSafeBoolean(response.isCCPAEnabled),
        isTestDealer: asSafeBoolean(response.isTestDealer),
        isWidgetHeightDynamic: asSafeBoolean(response.isWidgetHeightDynamic),
        overrideDRSActive: asSafeBoolean(response.overrideDRSActive),
        shopperEducationVideoURL: asSafeEmptyString(response.shopperEducationVideoURL),
        tradeInValuationLeadForm: asSafeBoolean(response.tradeInValuationLeadForm),
        enableTradeDecisionPage: asSafeBoolean(response.enableTradeDecisionPage),
        enableManualTradeDecision: asSafeBoolean(response.enableManualTradeDecision),
        useFnI2: asSafeBoolean(response.useFnI2),
        useZipCodeService: asSafeBoolean(response.useZipCodeService),
        website: asSafeEmptyString(response.website),
        financing: asSafeDealerFinancing(response.financing),
        incentives: asSafeDealerIncentives(response.incentives),
        reservation: asSafeDealerReservation(response.reservation),
        products: asSafeDealerProducts(response.products),
        accessories: asSafeDealerAccessories(response.accessories),
        leasing: asSafeDealerLeasing(response.leasing),
        TCPAProvider: asSafeEmptyString(response.TCPAProvider),
        brandingExperience: asSafeEmptyString(response.brandingExperience),
        defaultDealType: asSafeEmptyString(response.defaultDealType),
        isMenuPricingBasedOn: asSafeEmptyString(response.isMenuPricingBasedOn),
        labelSetName: asSafeEmptyString(response.labelSetName),
        locationToSendLeadsNew: asSafeEmptyString(response.locationToSendLeadsNew),
        locationToSendLeadsUsed: asSafeEmptyString(response.locationToSendLeadsUsed),
        longCustomDisclaimer: asSafeEmptyString(response.longCustomDisclaimer),
        partnerId: asSafeEmptyString(response.partnerId),
        shortCustomDisclaimer: asSafeEmptyString(response.shortCustomDisclaimer),
        testDriveServiceProvider: asSafeEmptyString(response.testDriveServiceProvider),
        theme: asSafeEmptyString(response.theme),
        themeRedesign: asSafeEmptyString(response.themeRedesign),
        tradeInValuationProvider: asSafeEmptyString(response.tradeInValuationProvider),
        canNegotiateNew: asSafeBoolean(response.canNegotiateNew),
        canNegotiateUsed: asSafeBoolean(response.canNegotiateUsed),
        isTradeInBranded: asSafeBoolean(response.isTradeInBranded),
        overrideDRSAccountId: asSafeEmptyString(response.overrideDRSAccountId),
        dealerTrackID: asSafeNumber(response.dealerTrackID),
        ownerId: asSafeNumber(response.ownerId),
        TCPAConsentText: asSafeEmptyString(response.TCPAConsentText),
        applyTaxesAndFees: asSafeBoolean(response.applyTaxesAndFees),
        applyTaxesAndFeesNewOptions: asSafeEmptyString(response.applyTaxesAndFeesNewOptions),
        cash: asSafeDealerCash(response.cash),
        leadRoutingOverrideSettings: asSafeLeadRoutingOverrideSettings(response.leadRoutingOverrideSettings),
        tradeInValuationICOProviderUrl: asSafeEmptyString(response.tradeInValuationICOProviderUrl),
        creditTiers: asSafeCreditTier(response.creditTiers),
        phone: asSafeEmptyString(response.phone),
        isPaymentDriverPreferred: asSafeBoolean(response.isPaymentDriverPreferred),
        isPaymentDriverPreferredForCashDeals: asSafeBoolean(response.isPaymentDriverPreferredForCashDeals),
        accountStatus: asSafeEmptyString(response.accountStatus),
        isTestDriveAvailableSunday: asSafeBoolean(response.isTestDriveAvailableSunday),
        listingOverride: asSafeListingOverride(response),
        sponsorSetting: asSafeSponsorSetting(response),
        enableAdminRedesignUI: asSafeBoolean(response.enableAdminRedesignUI),
        reviewDealUrlEnabled: asSafeBoolean(response.reviewDealUrlEnabled),
        isRedesignEnabled: asSafeBoolean(response.isRedesignEnabled),
        isAccelerateCreditAppEnabled: asSafeBoolean(response.isAccelerateCreditAppEnabled),
        isConsumerFacingCreditDecisionEnabled: asSafeBoolean(response.isConsumerFacingCreditDecisionEnabled),
        isAccelerateCreditDecisionEnabled: asSafeBoolean(response.isAccelerateCreditDecisionEnabled),
        productSet: asSafeProductSet(response.productSet),
        creditProvider: asSafeEmptyString(response.creditProvider),
        routeOnePartyId: asSafeEmptyString(response.routeOnePartyId),
        sdpModalZIndex: asSafeNumber(response.sdpModalZIndex),
        isDocUploadEnabled: asSafeBoolean(response.isDocUploadEnabled),
        howItWorksVideoUrl: asSafeEmptyString(response.howItWorksVideoUrl),
        howItWorksPosterUrl: asSafeEmptyString(response.howItWorksPosterUrl),
        isVehicleDeliveryEnabled: asSafeBoolean(response.isVehicleDeliveryEnabled),
        enableDealerShowroomExperience: asSafeBoolean(response.enableDealerShowroomExperience),
        privacyNoticeUrl: asSafeEmptyString(response.privacyNoticeUrl),
        enableOccIntegration: asSafeBoolean(response.enableOccIntegration),
        showCreditApplicationDisclaimer: asSafeBoolean(response.showCreditApplicationDisclaimer),
        enableSwitchVehicle: asSafeBoolean(response.enableSwitchVehicle),
        tradeInHideExcellent: asSafeBoolean(response.tradeInHideExcellent),
        dmsIntegration: asSafeEmptyString(response.dmsIntegration),
        showDealPulseInManagerView: asSafeBoolean(response.showDealPulseInManagerView),
        showFlexiblePaymentOptionsAMD: asSafeBoolean(response.showFlexiblePaymentOptionsAMD),
        showFlexiblePaymentOptionsSalesView: asSafeBoolean(response.showFlexiblePaymentOptionsSalesView),
        isTargetPlatformR1J: asSafeBoolean(response.isTargetPlatformR1J),
        isVPPFromFIEEnabled: asSafeBoolean(response.isVPPFromFIEEnabled),
        leadGatePaymentOnDS: asSafeBoolean(response.leadGatePaymentOnDS),
        commonOrgId: asSafeEmptyString(response.commonOrgId),
        tradeInValuationProviderKBB: asSafeBoolean(response.tradeInValuationProviderKBB),
        tradeInValuationProviderKBBSalesView: asSafeBoolean(response.tradeInValuationProviderKBBSalesView),
        tradeInValuationProviderKBBICO: asSafeBoolean(response.tradeInValuationProviderKBBICO),
        tradeInValuationProviderKBBICOSalesView: asSafeBoolean(response.tradeInValuationProviderKBBICOSalesView),
        tradeInValuationProviderManual: asSafeBoolean(response.tradeInValuationProviderManual),
        tradeInValuationProviderManualSalesView: asSafeBoolean(response.tradeInValuationProviderManualSalesView),
        tradeInValuationProviderDefault: asSafeNullString(response.tradeInValuationProviderDefault),
        tradeInValuationProviderDefaultSalesView: asSafeNullString(response.tradeInValuationProviderDefaultSalesView),
        enableGhostVin: asSafeBoolean(response.enableGhostVin),
        printOfferShowSignatureLine: asSafeBoolean(response.printOfferShowSignatureLine)
    };
    dealer.listingOverride = asSafeListingOverride(response);
    return dealer;
}
