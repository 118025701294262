import { AccessoryEditableFields } from './EditForm.constants';

export const formErrorsInitialState = {
    [AccessoryEditableFields.MSRP]: '',
    [AccessoryEditableFields.COST]: '',
    [AccessoryEditableFields.LABOR_UNITS]: '',
    [AccessoryEditableFields.ACTIVATION_DATE]: '',
    [AccessoryEditableFields.DEACTIVATION_DATE]: '',
    [AccessoryEditableFields.FULL_PART_NAME]: '',
    [AccessoryEditableFields.SHORT_TITLE]: '',
    [AccessoryEditableFields.DESCRIPTION]: '',
    [AccessoryEditableFields.DISCLAIMER]: '',
    [AccessoryEditableFields.CATEGORY]: '',
    [AccessoryEditableFields.SUBCATEGORY]: '',
    [AccessoryEditableFields.PRODUCT_IMAGE_URL]: ''
};
