import React from 'react';
import MaterialTable, { Column, Options } from 'material-table';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import { IAccessoriesTableProps, IAccessory } from '../../../store/accessories/types';
import { tableIcons } from '../../common/utils';
import { useStyles } from './AccessoryTable.styles';
import { formatDate } from './formateDate';

const accessoriesTableOptions: Options<any> = {
    headerStyle: {
        color: '#191919',
        fontWeight: 'bold',
        overflowWrap: 'break-word',
        minWidth: '100%',
        padding: '5px'
    },
    pageSize: 50,
    pageSizeOptions: [10, 25, 50, 100],
    showFirstLastPageButtons: false,
    search: false,
    toolbar: false,
    padding: 'dense'
};
const checkPageSize = (list: IAccessory[]) => {
    if (accessoriesTableOptions.pageSize) {
        if (list.length && list.length !== accessoriesTableOptions.pageSize) {
            accessoriesTableOptions.pageSize = list.length;
        }
    }
};

const AccessoriesTable = (props: IAccessoriesTableProps) => {
    checkPageSize(props.list);

    const classes = useStyles();
    const columns: Column<IAccessory>[] = [
        {
            title: 'Manufacturer',
            field: 'manufacturer',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Part #',
            field: 'partNumber',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Full Part Name',
            field: 'fullPartName',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Year',
            field: 'vehicleYear',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Make',
            field: 'vehicleMake',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Model',
            field: 'vehicleModel',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Model Trim',
            field: 'modelTrim',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Category',
            field: 'category',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Subcategory',
            field: 'subcategory',
            cellStyle: { padding: '5px' }
        },
        {
            title: 'Active Accessories',
            field: 'isActive',
            cellStyle: { padding: '5px' },
            render: (rowData) => (
                <Switch
                    color="primary"
                    checked={rowData.isActive}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onChange={(event: React.ChangeEvent<HTMLElement>) => event.preventDefault()}
                />
            )
        },
        {
            title: 'Activation Date',
            field: 'activationDate',
            cellStyle: { padding: '5px' },
            render: (rowData) => formatDate(rowData.activationDate)
        },
        {
            cellStyle: { padding: '5px' },
            render: (rowData: IAccessory) => (
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`accessories/${rowData.id.toString()}/edit`}
                >
                    Edit
                </Button>
            )
        }
    ];
    return (
        <>
            <MaterialTable icons={tableIcons} columns={columns} data={props.list} options={accessoriesTableOptions} />
        </>
    );
};
export default AccessoriesTable;
