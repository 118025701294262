export const formatDate = (date: string | undefined, hours = false): string => {
    if (date && hours) {
        const dateObj = new Date(date);
        const options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        };

        return new Intl.DateTimeFormat('en-US', options).format(dateObj);
    } else if (date) {
        const dateObj = new Date(date);
        const options = { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC' };

        return new Intl.DateTimeFormat('en-US', options).format(dateObj);
    } else {
        return 'N/A';
    }
};
