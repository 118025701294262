import { FileUploadStatus, IUploadStatus } from '../types';

export const uploadStatusMockSuccess: IUploadStatus = {
    fileName: 'errors.txt',
    uploadInitiator: '',
    status: FileUploadStatus.success,
    successfullyProcessedCount: 100,
    processedWithErrorsCount: 0,
    uploadedAt: '2021-12-06T10:34:03.168Z'
};

export const uploadStatusMockError: IUploadStatus = {
    fileName: 'errors.csv',
    uploadInitiator: '',
    status: FileUploadStatus.fail,
    successfullyProcessedCount: 0,
    processedWithErrorsCount: 100,
    uploadedAt: '2021-12-06T10:34:03.168Z',
    errorReportUrl: 'https://url'
};

export const uploadStatusMockProcessing: IUploadStatus = {
    fileName: 'errors.csv',
    uploadInitiator: '',
    status: FileUploadStatus.processing,
    successfullyProcessedCount: 0,
    processedWithErrorsCount: 0,
    uploadedAt: '2021-12-06T10:34:03.168Z'
};

export const uploadStatusInitialState: IUploadStatus = {
    fileName: '',
    uploadInitiator: '',
    status: null,
    successfullyProcessedCount: 0,
    processedWithErrorsCount: 0,
    uploadedAt: ''
};
