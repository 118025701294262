import React from 'react';
import { FunctionComponent } from 'react';
import { IDealerToManage, IUpdateDealerCallbackFn, ICommonManagerFieldProps } from '../../store/manager/types';
import { DR3RadioBooleanField } from '../common/DR3RadioBooleanField';
import { ToggleLabelMap } from '../../constants/ToggleConstants';

const handleChangeMenuPricing = (oldDealer: IDealerToManage, updateDealerCallback: IUpdateDealerCallbackFn) => (value: boolean) => {
    const newDealer: IDealerToManage = {
        ...oldDealer,
        accessories: {
            ...oldDealer.accessories,
            hideMenuPricing: value
        }
    };
    updateDealerCallback(null, newDealer);
};

const HideAccessoriesMenuPricing: FunctionComponent<ICommonManagerFieldProps> = (props) => {
    if (!props.dealer) {
        return null;
    }

    const isDisabled = !props.dealer.products.hasAccessories;

    return (
        <DR3RadioBooleanField
            trueOption="Yes"
            falseOption="No"
            label={ToggleLabelMap.isAccessoriesPricingDisabled}
            value={props.dealer.accessories.hideMenuPricing}
            disabled={isDisabled}
            handleChange={handleChangeMenuPricing(props.dealer, props.updateDealerCallback)}
        />
    );
};
export default HideAccessoriesMenuPricing;
