import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        marginBottom: '20px'
    },
    errorText: {
        marginLeft: '14px'
    }
});
