import { Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { useState } from 'react';
import * as React from 'react';
import MySnackbarContentWrapper from '../../../../containers/components/MySnackbarContentWrapper';
import { IAuthFn, IWithAuthProps } from '../../../../utils/AuthTypes';
import { IWithApiProps } from '../../../../utils/withApi';
import { FILE_SIZE_LIMIT_IN_KB, INVALID_TOKEN_ERROR } from '../../Accessories.constants';
import { useStyles } from '../Filters.styles';
import { connect } from 'react-redux';
import { fetchUploadStatus } from '../../../../store/accessories/actions';
import { IApi } from '../../../../utils/Api';
import {
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_SUCCESS,
    SIZE_LIMIT_ERROR,
    UNSUPPORTED_FILE_FORMAT_ERROR,
    csvFileNameRegExpr
} from './UploadNewAccessories.constants';

export const getFileSizeInKB = (size: number) => Math.round(size / 1024);

interface IFetchUploadStatus {
    fetchUploadStatus: (auth: IAuthFn, api: IApi) => void;
}
interface ComponentProps extends IWithApiProps, IWithAuthProps, IFetchUploadStatus {}

export const UploadNewAccessories = (props: ComponentProps) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const closeError = () => setErrorMessage('');
    const closeSuccess = () => setSuccessMessage('');
    const showErrorMessage = (message: string) => setErrorMessage(message);
    const showSuccessMessage = (message: string) => setSuccessMessage(message);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.persist();
        const resetFile = () => (event.target.value = '');
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0];
        const fileSizeInKB = getFileSizeInKB(file.size);

        if (fileSizeInKB > FILE_SIZE_LIMIT_IN_KB) {
            showErrorMessage(SIZE_LIMIT_ERROR);
            resetFile();
            return;
        }

        if (!csvFileNameRegExpr.test(file.name)) {
            showErrorMessage(UNSUPPORTED_FILE_FORMAT_ERROR);
            resetFile();
            return;
        }

        const token = await props.auth.getAccessToken();

        if (!token) {
            showErrorMessage(INVALID_TOKEN_ERROR);
            resetFile();
            return;
        }

        try {
            const signedUrl = await props.api.generateAccessoriesUploadURL(file.name, token);
            await props.api.uploadAccessories(signedUrl, file);
            showSuccessMessage(FILE_UPLOAD_SUCCESS);
            await props.fetchUploadStatus(props.auth, props.api);
            resetFile();
        } catch (e) {
            showErrorMessage(FILE_UPLOAD_ERROR);
        }
    };

    const classes = useStyles();

    return (
        <>
            <Button variant="contained" component="label" color="primary" className={classes.button}>
                Upload New Accessories
                <input id="upload-input" accept="text/csv" type="file" hidden onChange={handleFileUpload} />
            </Button>
            <Snackbar
                data-test-id="error-snack-bar"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{ top: '80px' }}
                open={Boolean(errorMessage)}
                autoHideDuration={6000}
                onClose={closeError}
            >
                <MySnackbarContentWrapper variant="error" className="alertError" message={[errorMessage]} onClose={closeError} />
            </Snackbar>
            <Snackbar
                data-test-id="success-snack-bar"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{ top: '80px' }}
                open={Boolean(successMessage)}
                autoHideDuration={6000}
                onClose={closeSuccess}
            >
                <MySnackbarContentWrapper
                    variant="success"
                    className="alertSuccess"
                    message={[successMessage]}
                    onClose={closeSuccess}
                />
            </Snackbar>
        </>
    );
};

const withConnect = connect(null, { fetchUploadStatus });

export default withConnect(UploadNewAccessories);
