import { FunctionComponent } from 'react';
import useStyles from '../layout/styles';
import React from 'react';
import { ValidationTextField } from './ValidationTextField';
import { DR3FieldWrapper } from './DR3FieldWrapper';

interface IDR3HardCodeFieldProps {
    label: string;
    value: number | string | null;
    disabled: boolean;
}

// just a readonly text box
export const DR3HardCodeField: FunctionComponent<IDR3HardCodeFieldProps> = (props) => {
    const classes = useStyles({});
    return (
        <DR3FieldWrapper label={props.label}>
            <div>
                <ValidationTextField
                    variant="outlined"
                    disabled={props.disabled}
                    value={props.value}
                    className={classes.textField}
                />
            </div>
        </DR3FieldWrapper>
    );
};
