import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, match } from 'react-router-dom';
import { History } from 'history';
import { withAuth } from '@okta/okta-react';
import { RootState } from '../../../store';
import { selectAccessories } from '../../../store/accessories/selectors';
import { IAccessory } from '../../../store/accessories/types';
import withApi from '../../../utils/withApi';
import { fetchAccessoryById, fetchEditAccessory } from '../../../store/accessories/actions';
import GlobalWrapper from '../../layout/GlobalWrapper';
import { SEARCH_TAB_LABELS, TabSelected } from '../../../store/manager/types';
import { IAuthFn } from '../../../utils/AuthTypes';
import { IApi } from '../../../utils/Api';
import EditForm from './EditForm/EditForm';
import { CircularProgress } from '@material-ui/core';
import './AccessoriesEditStyles.scss';
import { AccessoryEditableFields } from './EditForm/EditForm.constants';

type TParams = { accessoryId?: string | undefined };

export interface IAccessoriesEditProps {
    history: History;
    match: match<TParams>;
    auth: IAuthFn;
    api: IApi;
    fetchAccessoryById: (auth: IAuthFn, api: IApi, id: string) => void;
    fetchEditAccessory: (auth: IAuthFn, api: IApi, accessory: IAccessory) => void;
    accessory: IAccessory;
    isLoadingForEdit: boolean;
}

export class AccessoriesEdit extends Component<IAccessoriesEditProps> {
    editFormRef = React.createRef<EditForm>();

    async componentDidMount() {
        const { auth, api, fetchAccessoryById, match } = this.props;
        const { accessoryId = '' } = match.params;
        await fetchAccessoryById(auth, api, accessoryId);
    }

    public saveAccessory = async () => {
        if (this.editFormRef.current) {
            await this.editFormRef.current.validateFormIfNothingChange();
            if (!this.editFormRef.current.validateForm()) return;

            const editedActivationDateObj = this.editFormRef.current.state.activationDate;
            const activationDate = editedActivationDateObj
                ? new Date(editedActivationDateObj.getTime() - editedActivationDateObj.getTimezoneOffset() * 60000).toISOString()
                : undefined;

            const editedDeactivationDateObj = this.editFormRef.current.state.deactivationDate;
            const deactivationDate = editedDeactivationDateObj
                ? new Date(
                      editedDeactivationDateObj.getTime() - editedDeactivationDateObj.getTimezoneOffset() * 60000
                  ).toISOString()
                : undefined;
            const editedAccessory = {
                ...this.editFormRef.current.state.formData,
                [AccessoryEditableFields.ACTIVATION_DATE]: activationDate,
                [AccessoryEditableFields.DEACTIVATION_DATE]: deactivationDate
            };

            await this.props.fetchEditAccessory(this.props.auth, this.props.api, editedAccessory);
            this.navigateHome();
        }
        return false;
    };

    public navigateHome = () => {
        this.props.history.push('/accessories', { tab: SEARCH_TAB_LABELS.ACCESSORIES });
    };

    render() {
        return (
            <GlobalWrapper
                dealer={null}
                onClickSave={this.saveAccessory}
                onClickHome={this.navigateHome}
                isAccessoryPage={true}
                tabSelected={TabSelected.ACCESSORIES}
            >
                {this.props.isLoadingForEdit ? (
                    <CircularProgress className="spinner" />
                ) : (
                    <EditForm accessory={this.props.accessory} ref={this.editFormRef} />
                )}
            </GlobalWrapper>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    accessory: selectAccessories(state).accessory,
    isLoadingForEdit: selectAccessories(state).isLoadingForEdit
});

const withConnect = connect(mapStateToProps, { fetchAccessoryById, fetchEditAccessory });

export default withRouter(withConnect(withAuth(withApi(AccessoriesEdit))));
