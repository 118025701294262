import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        marginTop: '32px'
    },
    search: {
        width: '40%'
    },
    button: {
        textTransform: 'none',
        marginLeft: '15px',
        fontWeight: 'bold'
    },
    filterText: {
        fontWeight: 'bold',
        marginRight: '20px'
    }
});
