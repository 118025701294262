import { IHandleUpdateFieldChange, IHandleFieldChange, PartnerMapping, IdPartnerMapping } from '../../store/manager/types';
import MaterialTable, { Column, Options } from 'material-table';
import React, { FunctionComponent, useMemo, useRef } from 'react';
import { DR3FieldWrapper } from './DR3FieldWrapper';
import { tableIcons } from './utils';
import { useStyles } from '../accessories/Accessories.styles';
import { isRowDeletable } from '../utilities/getIdMappings';

interface IDR3PartnerMappingTableProps {
    mappingData: PartnerMapping[];
    onUpdate: IHandleUpdateFieldChange<PartnerMapping>;
    onAdd: IHandleFieldChange<PartnerMapping>;
    onDelete: IHandleFieldChange<PartnerMapping>;
    label: string;
    columns: Column<PartnerMapping>[];
    tableOptions: Options<PartnerMapping>;
}

export const DR3PartnerMappingTable: FunctionComponent<IDR3PartnerMappingTableProps> = (props) => {
    const tableRef = useRef<any>(null);

    // Workaround for table due to a bug in this and higher versions
    // Visit https://github.com/mbrn/material-table/issues/1480 to learn more
    useMemo(() => {
        tableRef?.current?.dataManager?.changePageSize(props.tableOptions.pageSize);
    }, [props.tableOptions.pageSize]);

    const classes = useStyles({});

    return (
        <DR3FieldWrapper label={props.label} isTableData={true} className={classes.partnerMappingsSection}>
            <MaterialTable
                tableRef={tableRef}
                style={{ height: '100%', overflowY: 'auto' }}
                options={props.tableOptions}
                icons={tableIcons}
                title={props.label}
                columns={props.columns}
                data={props.mappingData}
                editable={{
                    isEditable: (rowData) => (rowData.isEditable === undefined ? true : rowData.isEditable),
                    isDeletable: (rowData) => isRowDeletable(rowData as IdPartnerMapping),
                    onRowUpdate: (newData: any, oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                props.onUpdate(newData, oldData);
                            }, 100);
                        }),
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                props.onAdd(newData);
                            }, 100);
                        }),
                    onRowDelete: (oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                props.onDelete(oldData);
                            }, 100);
                        })
                }}
            />
        </DR3FieldWrapper>
    );
};
